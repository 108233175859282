$(function() {
    $('.block__btn').click(function() {
        const elem = $(this).siblings('.block__info');
        $('.block__info').not(elem).hide();
        elem.toggle();
    });

    $('.block__close').click(function() {
        $(this).parents('.block__info').hide();
    });

    function range(input){
        const val = input.val();
        const length = input.attr('max') - input.attr('min');
        const n = (val / length) * 100 - 11;
        input.css({
            'background-image':'-webkit-linear-gradient(left ,#1C1C1C 0%,#1C1C1C '+n+'%,#A1A1A6 '+n+'%, #A1A1A6 100%)'
        });

        input.siblings('.range_labels').find('span').removeClass('active');
        const text = input.siblings('.range_labels').find(`span[data-val=${input.val()}]`).addClass('active').text();
        input.siblings('.range_view').text(text);
    }

    $('.input_range').each(function() {
        range($(this));
    });

    $('body').on('input', '.input_range', function() {
        range($(this));
        let container = $(this).parents('.colocation__item');
        config(container);
    });

    $('body').on('click', '.min_number', function() {
        let input = $(this).siblings('input');
        let min = input.attr('min');
        let val = input.val();
        if(val > min) input.val(+val - 1);
        let container = $(this).parents('.colocation__item');
        config(container);
    });

    $('body').on('click', '.max_number', function() {
        let input = $(this).siblings('input');
        let max = input.attr('max');
        let val = input.val();
        if(val < max) input.val(+val + 1);
        let container = $(this).parents('.colocation__item');
        config(container);
    });

    $('.colocation__item').each(function() {
        configType($(this));
        config($(this));
    });

    $('body').on('change', '.inet_input, .line1__power, .input_port, .ip, .local, .comment-area', function() {
        let container = $(this).parents('.colocation__item');
        config(container);
    });

    function config(container){
        let number_unit = container.find('.input_range1').val();
        container.find('#config_unit').val(number_unit + 'U');
        let price_unit = number_unit * +services.units.price;
        container.find('#config_unit_price').val(price_unit + ' ' + grn_mes);

        let number_power = container.find('.input_range2').val();
        container.find('#config_power').val(number_power * 100 + 'W');
        let price_power = number_power * +services.power.price;
        container.find('#config_power_price').val( price_power + ' ' + grn_mes);

        let price_socket = container.find('.line1__power').is(':checked') ? +services.backup_socket.price : 0;
        container.find('.config_socket_price').val(price_socket ? price_socket + ' ' + grn_mes : '-');

        let number_port = container.find('.input_port').val();
        container.find('.config_port').val(number_port + 'шт.');
        let price_port = number_port * +services.ports.price;
        container.find('.config_port_price').val( price_port + ' ' + grn_mes);

        let number_ip = container.find('.ip').val();
        container.find('.config_ip').val(number_ip + 'шт.');
        let price_ip = number_ip * +services.real_ips.price;
        container.find('.config_ip_price').val( price_ip + ' ' + grn_mes);

        let number_local = container.find('.local').val();
        container.find('.config_local').val(number_local + 'шт.');
        let price_local = number_local * 349;
        container.find('.config_local_price').val( price_local + ' ' + grn_mes);

        let number_inet = container.find('.inet_input:checked');
        container.find('.config_inet').val(number_inet.attr('data-speed'));
        let price_inet = +container.find('.inet_input:checked').attr('data-price') * number_port;
        container.find('.config_inet_price').val( price_inet + ' ' + grn_mes);

        let price_vlan = container.find('.vlan').is(':checked') ? 48 : 0;
        container.find('.config_vlan_price').val(price_vlan ? price_vlan + grn : '-');

        let price_all = price_unit + price_power + price_socket + price_port + price_ip + price_local + price_inet + price_vlan;
        container.find('.config_all_price').val(price_all + ' грн').attr('data-price', price_all);
        serversConfig(container);
    }

    function addServerForm(container) {
        const i = container.data('i');
        const items = ['units', 'width', 'height', 'length', 'power', 'packet_id', 'backup_socket', 'ports', 'real_ips', 'local_channels', 'comment'];
        for (let item of items) {
            $('#serversForm').append(`<input type="hidden" name="Servers[${i}][${item}]">`);
        }
    }

    function serversConfig(container) {
        const i = container.data('i');
        $(`input[name="Servers[${i}][units]"]`).val(container.find('.input_range1').val());
        $(`input[name="Servers[${i}][width]"]`).val(container.find('.size-input_1').val());
        $(`input[name="Servers[${i}][height]"]`).val(container.find('.size-input_2').val());
        $(`input[name="Servers[${i}][length]"]`).val(container.find('.size-input_3').val());
        $(`input[name="Servers[${i}][power]"]`).val(container.find('.input_range2').val());
        $(`input[name="Servers[${i}][packet_id]"]`).val(container.find('.inet_input:checked').val());
        $(`input[name="Servers[${i}][backup_socket]"]`).val(container.find('.line1__power').is(':checked') ? 1 : 0);
        $(`input[name="Servers[${i}][ports]"]`).val(container.find('.input_port').val());
        $(`input[name="Servers[${i}][real_ips]"]`).val(container.find('.ip').val());
        $(`input[name="Servers[${i}][local_channels]"]`).val(container.find('.local').val());
        $(`input[name="Servers[${i}][comment]"]`).val(container.find('.comment-area').val());
    }

    let count = 0;

    $('body').on('click', '.colocation__add-btn', function() {
        count++;

        let elem = $(this).parents('.colocation__item');
        let clone = elem.clone();
        clone.attr('data-i', count);

        clone.find('.input_range1').val(1);
        range(clone.find('.input_range1'));

        clone.find('.config-type-wrapper1 label').attr('for', 'config-type1_'+count);
        clone.find('.config-type-wrapper2 label').attr('for', 'config-type2_'+count);
        clone.find('.config-type_1').attr('name', 'config-type_'+count).attr('id', 'config-type1_'+count).prop('checked', true);
        clone.find('.config-type_2').attr('name', 'config-type_'+count).attr('id', 'config-type2_'+count).prop('checked', false);
        configType(clone);

        clone.find('.input_range2').val(1);
        range(clone.find('.input_range2'));

        clone.find('.line1__power-wrapper').attr('for', 'input_text1_'+count);
        clone.find('.line1__label').attr('for', 'input_text1_'+count);
        clone.find('.line1__power').attr('id', 'input_text1_'+count).prop('checked', false);

        clone.find('.input_port').val(1);
        clone.find('.local').val(0);

        clone.find('.comment-area').val('');

        clone.find('.inet_input').each(function(i) {
            const gid = $(this).val();
            clone.find('.label-inet'+gid).attr('for', 'inet'+gid+'_'+count);
            clone.find('.inet'+gid).attr('id', 'inet'+gid+'_'+count).attr('name', 'inet_'+count).prop('checked', (i == 1 ? true : false));
        });

        clone.find('.colocation__close').show();
        clone.find('.colocation__remove-btn').show();

        clone.appendTo('.colocation__container');
        addServerForm(clone);
        config(clone);
    });

    $('body').on('click', '.colocation__remove-btn', function() {
        $(this).parents('.colocation__item').remove()
    });

    function configType(container) {
        const type1 = container.find('.config-type_1');
        const type2 = container.find('.config-type_2');
        if (type1.prop('checked')) {
            container.find('.sizes').addClass('disable');
            container.find('.range1').removeClass('disable');
            container.find('.size-input').val('');
        } else if (type2.prop('checked')) {
            container.find('.range1').addClass('disable');
            container.find('.sizes').removeClass('disable');
        }
    }

    $('body').on('keydown', '.size-input', function() {
        if (event.keyCode < 48 || event.keyCode > 57) {
            event.returnValue= false;
        }
    });

    $('body').on('click', '.btn-comment', function() {
        const comment = $(this).siblings('.comment-area');
        comment.slideToggle(() => {
            if (comment.css('display') == 'none') {
                $(this).addClass('closed');
            } else {
                $(this).removeClass('closed');
            }
        });
    });

    $('body').on('change', '.config-type', function() {
        const container = $(this).parents('.colocation__item');
        configType(container);
    });

    $('body').on('change', '.size-input', function() {
        const container = $(this).parents('.colocation__item');
        const size1 = container.find('.size-input_1').val();
        const size2 = container.find('.size-input_2').val();
        const size3 = container.find('.size-input_3').val();

        if (size1 && size2 && size3) {
            const standart = 430 * 430 * 180;
            const v = size1 * size2 * size3;
            const unit = Math.ceil(v / standart) * 3;
            const input = container.find('.input_range1');
            input.val(unit < 10 ? unit : 10);
            range(input);
            config(container);
        }
    });

    $('.server__minimize').click(function() {
        $(this).parents('.server').find('.server__wrapper').slideToggle();
    });

    $('.btn-sidebar').click(function() {
        $('.sidebar').slideToggle();
    });

    $(window).resize(function() {
        if($(window).width() > 991) {
            $('.sidebar').removeAttr('style');
        }
    });

    $('#contact-form').submit(function() {
        const th = $(this);

        $.ajax({
            type: 'POST',
            url: '/site/contact',
            data: $(this).serialize(),
            success: function () {
                $(th).find('.success').addClass('active').css('display', 'flex').hide().fadeIn()
                    .find('.success-true').show();
                setTimeout(function () {
                    $(th).find('.success').css('display', 'none').removeClass('active').fadeOut()
                        .find('.success-true').hide();
                    $(th).trigger("reset");
                }, 3000);
            },
            error: function () {
                $(th).find('.success').addClass('active').css('display', 'flex').hide().fadeIn()
                    .find('.success-false').show();
                setTimeout(function () {
                    $(th).find('.success').css('display', 'none').removeClass('active').fadeOut()
                        .find('.success-false').hide();
                    $(th).trigger("reset");
                }, 3000);
            }
        });
        return false;
    });

    $('.scrollto').on('click', function() {
        let href = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(href).offset().top
        }, {
            duration: 370,
            easing: 'linear'
        });
        return false;
    });
});






































